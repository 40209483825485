.pane {

    display: flex;

    &.vertical {
        flex-direction: column;
        height: 100%;
    }

    &.horizontal {
        flex-direction: row;
        width: 100%;
    }

    .section {

        &.grow {
            flex-grow: 1;
            overflow: auto
        }

    }

}