.childIndicator {
    min-width: 30px !important;
    max-width: 30px !important;

    height: 50px !important;
    align-items: center;

    &.mobile {
        position: absolute;
        left: .5em;
        top: 0;
    }
}

.row {
    &.mobile {
        padding-left: 1.5em;
    }
}

/* //INFO: scheint nicht verwendet zu werden

.list {
    display: flex;
    flex-direction: column;
}

.link {
    color: var(--ion-text-color);
    text-decoration: none;
    width: 100%;
}

.row {

    &.header {
        font-weight: bold;
    }

    padding: 1em;

    display: flex;
    flex: 1;
    flex-wrap: wrap;

    align-items: center;

    border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), .4);
}

.center {
    display: flex;
    align-items: center;
}

.menuIcon {
    padding: 10px;
}

.col {

    display: flex;
    flex: 1;
    flex-grow: 0;


    img {
        display: inline-block;
        padding: .5em;
        max-height: 40px;
        max-width: 40px;
    }


    &.systemKey {

        min-width: 180px;
        max-width: 180px;
        align-items: center;

        img {
            padding: 0;
            max-height: 35px;
            max-width: 35px;
        }

        span {
            padding-left: 5px;
        }
    }

    &.status {
        min-width: 240px;
        max-width: 240px;
        justify-content: end;
        flex-wrap: wrap;
    }


    &.grow {
        flex-grow: 1;
        max-width: 100%;
        min-width: 0;
        flex-wrap: wrap;
    }

    &.fullwidth {
        min-width: 100%;
        max-width: 100%;
    }

    &.name {
        text-decoration: none;
    }

    &.settingsIcon {
        min-width: 50px !important;
        max-width: 50px !important;

        ion-icon {
            padding: 0 16px;
        }
    }

}

.container {
    --padding-start: 0px;
    --inner-padding-end: 0px;
}

.row:hover {
    background-color: whitesmoke;
}

.menu {
    ion-content {
        --max-height: 50%
    }
} */

.CustomerListDropdownBox {
    max-width: 220px
}