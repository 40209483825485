.loader {
    width: 100px;
    height: 100px;
    border: 8px solid orange;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: spin 1s linear infinite;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}