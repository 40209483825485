.text {
    margin-top: 5px;
    margin-bottom: 5px;
}

.overflow {
    overflow: inherit
}

.content {
    width: 100%;
}