.list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
}

/* ion-popover *::-webkit-scrollbar {
    display: none;
} */

.link {
    color: var(--ion-text-color);
    width: 100%;
    text-decoration: none;
}

.pointer {
    cursor: pointer;
}

.row {

    /* align-items: start; */

    &.header {
        font-weight: bold;
    }

    &.even {
        /* background: whitesmoke; */
    }

    &.odd {}

    padding: 1em;

    display: flex;
    // flex: 1;
    flex-wrap: wrap;

    &.pullUp {
        align-items: start;
    }

    justify-content: space-between;

    border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), .4);
}

.center {
    display: flex;
    align-items: center;
}

.settings {
    position: absolute;
    top: 0;
}

.col {

    display: flex;
    flex: 1;
    flex-grow: 0;

    min-width: 200px;
    max-width: 200px;

    &.flexNextLine {
        min-width: 100vw;
    }

    svg {
        // display: inline-block;
        margin: .5em;

        // max-height: 40px;
        // max-width: 40px;
    }



    &.systemKey {

        min-width: 180px;
        max-width: 180px;
        align-items: center;

        img {
            padding: 0;
            max-height: 35px;
            max-width: 35px;
        }

        span {
            padding-left: 5px;
        }
    }

    &.status {
        min-width: 240px;
        max-width: 240px;
        justify-content: end;
        align-items: baseline;
        flex-wrap: wrap;
    }


    &.grow {
        flex-grow: 1;
        max-width: 100%;
        min-width: 0;
        flex-wrap: wrap;
    }

    &.fullwidth {
        min-width: 100%;
        max-width: 100%;
    }

    &.name {
        text-decoration: none;
    }

    &.settingsIcon {

        min-width: 50px !important;
        max-width: 50px !important;
        height: 50px !important;
        align-items: center;

        &.begin {
            justify-content: start;
            align-self: center;
        }

        &.end {
            justify-content: end;
            align-self: start;
        }

        &.mobile {
            position: absolute;
            top: 0;
            right: 1em;
        }

        // ion-icon {
        //     padding: 16px 16px;
        // }
    }


}

.mobile {
    width: 100vw;

}

.marginLeft {
    margin-left: 31px;
}

.menuIcon {
    padding: 10px;
    position: absolute !important;
    height: 100%;
    width: 5%;
    left: 0;
    top: 0;

}

.container {
    --padding-start: 0px;
    --inner-padding-end: 0px;
}

.row:hover {
    background-color: whitesmoke;
}

.menu {
    /*    ion-content {
        --max-height: 50%
    } */

    @media screen and (max-width:900px) {
        ion-list {
            max-height: 57%;
            overflow: auto;
        }
    }

}

.rowTextCenter {
    margin: auto 0;
}

.rowTextCenterWithMarginAround {
    margin: auto 10px;
}

.noContent {
    div {
        width: fit-content;
        margin: 0 auto;
    }
}

.rowContainer {
    overflow: auto;
    flex-grow: 1;
}