:root {
    --device-content-height: 67vh;
}

[hidden] {
    display: none !important;
}

ion-app.md {
    --accordion-no-expand-extra: 18px;
}

ion-app>ion-header>ion-item>ion-title {
    max-width: calc(100% - 32px);
}

/* .overscroll::before {
    top: -2px;
    bottom: -2px;
}
 */
/* 
.overscroll::after {
    top: -2px;
    bottom: -2px;
} */