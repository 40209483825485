.content {
    position: 'absolute';
    z-index: 21
}

.overlaySettings {

    margin: 0;
    padding: 0;
    position: absolute;
    width: 100vw;
    z-index: 21;

    :host(ion-item) {
        overflow: auto !important;
    }
}

.headerTitle {
    margin: 5px auto;
}

.pointer {
    cursor: pointer;
}