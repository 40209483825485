.newSystemModal {
    --height: fit-content;


}

.option {
    text-align: right;
}

.buttonContainer {
    display: flex;
    justify-content: space-evenly;
}

.padding {
    padding: 26px;
}

.marginBottom {
    margin-bottom: 30px;
}

.marginTop {
    margin-top: 30px;
}

.pointer {
    cursor: pointer;
}

.currentType {
    border: .5px solid black;
    border-radius: 5px;
    background-color: whitesmoke
}

.typeButton {
    padding: 5px;
    margin: 5px auto;
}

.center {
    text-align: center;
}

.titleCenter {
    width: fit-content;
    margin: auto;
}