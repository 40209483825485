.floor {
    cursor: pointer;


}

.textCenter {
    text-align: center;
}


.content {
    height: 15vh;
    overflow: auto;
}