.leafletContainer {
    width: 100%;
    min-height: 100%;
    background: white;

    .leaflet-popup-content {
        width: 200px !important;
    }



    .popover {
        width: 200px;

        .center {
            margin: 0 auto;
        }
    }
}