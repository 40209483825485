.title {
    display: flex;
    justify-content: center;
}

.plan {
    height: 97%;

    @media (max-width:400px) {
        height: 95%;
    }

    .contentContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;

        @media (max-width:400px) {
            justify-content: unset;
            flex-direction: column-reverse;

            main {
                min-width: 100%;
                min-height: 90%;
                height: 57vh;
            }
        }

        @media (min-width:400px) {

            aside {
                min-width: 33%;
                max-width: 40%;
            }

            main {
                min-width: 60%;
                max-width: 80%;
                border: .5px solid black;
                height: 70vh;
                overflow: hidden
            }
        }

        .floor,
        .device {
            cursor: pointer;
        }
    }
}