.mobile {
    /*position: absolute;*/
    width: 100vw;
    display: flex;
    background-color: transparent;
    justify-content: space-evenly;

    background-color: var(--ion-color-tertiary);

    img,
    svg {
        width: 40px;
        height: 40px;

        @media screen and (max-height: 700px) {
            width: 30px;
            height: 30px;
        }
    }

    ion-button {
        width: 80px;
        height: 80px;

        @media screen and (max-height: 760px) {

            @media screen and (min-width: 300px) {
                width: 70px;
                height: 70px;
            }

            @media screen and (max-width: 299px) {
                width: 60px;
                height: 60px;
            }
        }

        --border-width: 5px;
        --border-style: solid;
        --border-color: grey;

        &.alarm {
            --border-color: rgb(230, 0, 28)
        }

        &.alert {
            --border-color: rgb(239, 193, 0);
        }

        &.no-border {
            --border-style: none;
        }
    }
}

.status {
    // position: absolute;
    display: flex;
    justify-content: space-around;
    bottom: 7px;
    border-radius: 0;
    margin: 0;
    padding: 4px 0;
    width: 100vw;
}

.desktop {
    margin-right: 20px;
    display: flex;
    width: 30vw;
    justify-content: flex-end;
    background: transparent;


    img,
    svg {
        width: 60px;
        height: 60px;

        &.timer {
            min-width: 60px;
        }
    }

    ion-button {
        width: 70px;
        height: 70px;
        margin-left: 5%;
        --border-width: 5px;
        --border-style: solid;
        --border-color: grey;

        &.alarm,
        &.explorationTimer {
            --border-color: rgb(230, 0, 28)
        }

        &.alert,
        &.reactionTimer {
            --border-color: rgb(239, 193, 0);
        }

        &.no-border {
            --border-style: none;
        }
    }


}

.reset {
    font-size: 1rem
}

.events {
    border: 1px solid black;
    background-color: whitesmoke;
    min-width: 600px;
    padding: 8px;
    height: 80px;
    margin: auto 0;
    display: flex;
    justify-content: space-evenly;
    border-radius: 2px;

    ion-note {
        font-size: 0.7rem;
    }

    div {
        text-align: center;
    }

    ion-badge {
        margin-top: 2px;
    }
}

/* .eventTitle {
    text-align: center;
    margin: 5px 0;
} */