.containerInput {
    /* text-align: right; */
    width: 100%;
    margin: 10px 0
}

.inputAlign {
    text-align: center;
}

.container {
    width: 100%;
    display: flex;
}

.label {
    //margin: 6px 0;
    white-space: break-spaces !important;
}



.noArrows {

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}

.overflow {
    overflow: inherit
}


.subLabel {
    font-size: small;
    max-width: fit-content;
}

.flexCenter {
    display: flex;
    justify-content: center;
}

.group {
    width: 50%;

    &.label-text {
        --padding-start: 5px !important;
    }
}

.element {
    width: 30%;

    div.label-text {
        --padding-start: 5px !important;
    }
}

.devider {
    width: 10%;
    text-align: center;
    vertical-align: middle;
    margin: 4% 0 0 0;
}

.row,
.line {
    width: 100%;
    /* margin: 10px 0; */
    height: 100%;
}

.line {
    display: flex;
    justify-content: space-evenly;

    &.input {
        display: flex;
        justify-content: space-evenly;
    }
}

.row {
    section {
        margin: 20px 0;
    }
}