.newSystemModal {
    --height: fit-content;

    .header {
        width: fit-content;
        margin: auto
    }

    .error {
        width: fit-content;
        margin: 5px auto
    }
}

.option {
    text-align: right;
}

.buttonContainer {
    display: flex;
    justify-content: space-evenly;
}

.marginBottom {
    margin-bottom: 30px;
}

.marginTop {
    margin-top: 30px;
}