.container {
    height: 43%;


    .header {
        display: flex;
        justify-content: space-between;

        .headItem {
            min-width: 48%;
            max-width: 48%;
            overflow: hidden;
            height: calc(25px);
            margin: 0 1%;
            font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;

            .currentDevice {
                display: flex;
                text-align: center;
                margin: 0 10px;

                .img {
                    margin: 0 5px;
                    padding: 0;
                    width: 25px;
                    height: fit-content;
                }
            }
        }
    }

    ion-button,
    ion-icon {
        cursor: pointer;
    }

    /* .group {
        width: 91%;
        margin: auto;
        display: flex;
        justify-content: space-between;

        ion-select {
            width: 40%;
            padding: 0 15px;
            background: white;
            color: black;
        }
    } */


    .contentContainer {

        display: flex;
        justify-content: space-between;
        height: 70vh;
        overflow: hidden;

        .content {
            width: 50%;

            .searchHeader {
                margin: 5px auto;
            }

            .search {
                display: flex;
                margin: 10px auto;
                width: 100%;

                ion-input {
                    background: whitesmoke;
                    color: black;
                }

                ion-button {
                    margin: 0 5px;
                    overflow: auto;
                    min-height: 100%;
                    max-height: 100%;
                }
            }

            ion-list {
                height: 40.5%;
                overflow: auto;

                .device {
                    cursor: pointer;

                    .text {
                        text-align: center;
                        width: 130px;
                    }
                }
            }

        }
    }
}