.mobile {
    overflow: auto;
    height: var(--device-content-height);
    width: 100vw;
}



.container {
    padding-top: 0 !important;

    &.input-wrapper {
        width: 0;
    }

    ion-accordion:last-child {
        border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
    }

    ion-card {
        box-shadow: none;

    }
}


.listContainerMoblie {
    min-width: 100vw;
    //min-height: var(--device-content-height);
    --padding-top: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;

    ::-webkit-scrollbar {
        display: none;
    }
}

.listContainerWeb {
    padding-top: 0;
    border: 1px solid black;
    overflow: hidden;
    /* height: 30vh; */
    height: 60vh;

    /* width: 60vw; */
    ::-webkit-scrollbar {
        display: none;
    }
}

.childId {
    min-width: 10%;
}

.childTitle {
    min-width: 60%;
}

.label {
    width: 100px;
    margin-right: 0;
    margin-inline-end: 0;
}

.paddingRight {
    width: 70px;
}

.none {
    display: none;
}

.center {
    width: fit-content !important;
    margin: 0 auto;
}