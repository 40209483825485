.overview-main-container {
    ion-list {
        --padding-start: 16px;
        --padding-end: 16px
    }

    .ion-item-mobile {
        ion-list {
            --padding-start: 0;
            --padding-end: 0
        }

        ion-item {
            --padding-start: 10px;
            --padding-end: 0
        }
    }

    .firstColum {
        min-width: 45%;
    }
}

.card {
    width: 80vw;
    min-height: 30vh;
    max-height: 80vh;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
}

.overflow {
    overflow: inherit !important;
    position: relative;
}

ion-item .item-native {
    position: unset;
}