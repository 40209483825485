.map {

    width: 100%;
    height: 100%;

    .eventContainer {
        display: flex;
        justify-content: end;
        width: 100%;

        .eventItem {
            margin: 0 10px
        }
    }
}

.error {
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
        width: 50%;
        height: 50%;
    }

    .content {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .msg {
        width: fit-content;
        height: fit-content;
        text-align: center;
    }
}


.blink {

    //border-width: var(--border-width);
    animation: blink-animation 1s infinite;
}


@keyframes blink-animation {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }


}