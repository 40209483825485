.container {
    border-radius: 100%;
    display: flex;
    justify-content: center;
    width: fit-content;
    height: fit-content;

    ion-icon {
        margin: 0;
        padding: 0;
    }

    ion-checkbox {
        width: 0;
        height: 0;
        padding: 20px;
    }

    &:not(+ img) {
        background-color: black;
    }
}

.hasSiblings {
    position: absolute;
}

.noSiblings {
    height: 100%;
    align-items: center;
    border-radius: 0;
}