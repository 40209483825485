@use "../../../global.scss";

/* .svg-item {
    margin: 0 auto;
    display: block;
    width: 40px;
    height: 40px;
}
 */

.event-list-container-mobile {
    overflow: auto;
    height: 100%;
    width: 100vw;
    padding-bottom: 2em;
}

.event-card-start {
    margin: 0 10px 10px 10px;

    text-align: center;
    width: 70px;
    height: 60px;
}

.event-container {
    margin: 10px 0 0 0;
    align-items: start;
    --padding-start: 0 !important;
    padding-inline-start: 0 !important
}


.event-main-list-container {
    //overflow: hidden !important;
    padding: 0;
    height: 100%;
    width: 100%;
    //ion-list {
    //overflow: auto;
    //     max-height: 100%;

    //     @media (max-height: 700px) and (max-width:900px) {
    //         min-height: 60vh;
    //         max-height: 60vh;
    //     }

    //     @media (max-width: 500px) and (min-height: 701px) and (max-height: 950px) {
    //         min-height: 72vh;
    //         max-height: 72vh;
    //     }
    // }

    // @media screen and (max-width:900px) {
    //min-height: var(--device-content-height);
    //max-height: var(--device-content-height);
    //}
}

.event-container ion-card {
    box-shadow: none;


}

.event-card-datetime {

    width: 100%;
    padding-bottom: 1em;

    div {
        width: 100%;
        text-align: end;
    }

}

/* 
.event-list-container-web ion-card {
    box-shadow: none;
}
 */

.event-list-container-web {
    border: 1px solid black;
    overflow: auto;
    height: 30vh;
    /* width: 30vw; */

}

.list-title-container {
    height: 40px;
    text-align: center;
}

.event-icon-wrapper {
    padding: 0;
}

.event-fontsize-title {
    font-size: smaller;
}

.align-right {
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
}

.align-center {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}