.containerInput {
    width: 100%;
    margin: 5px 0;

    .clearIcon {
        position: absolute;
        top: 60%;
        right: 10%;
        z-index: 2;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }


    // [class~="native-input"] {
    //     width: 85%;
    //     max-width: 85%;
    // }


    /*   &:hover,
    &:focus {
        .clearIcon {
            display: unset;
        }
    } */


}

.overflow {
    overflow: inherit
}

.inputAlign {
    text-align: left;
}

.container {
    width: 100%;
}

.textLeft {
    text-align: left;
}

.textRight {
    text-align: right;
}


.label {
    white-space: break-spaces !important;
    padding-bottom: 10px;
    width: fit-content;
}

.required {
    color: red;
    margin-left: 2px;
}


.flex {
    display: flex;
}

.justifStart {
    justify-content: start;
}

.tooltip {
    margin: 15px 0 0 0px;
    padding: 0;
}



/* .hasFocus {
    --highlight-background: var(--ion-color-danger);

    ion-label {
        color: var(--ion-color-danger) !important;
    }
} */