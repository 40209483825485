.webInput {
    max-width: 900px;
    background-color: whitesmoke;
    margin-top: 5px;
}

.required {
    color: red;
    margin-left: 2px;
}

.setting ion-label {
    font-size: 1.1rem !important;
}