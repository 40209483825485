.position {
    float: right;
}

.vertikalPosition {
    vertical-align: sub;
}

.buttonSpace {
    margin: 5px 0 5px 5px
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.form-file-upload {
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    height: 6rem;
    width: 20rem;
    max-width: 100%;
    text-align: center;
    position: relative;
    float: right;
}


.label-file-upload {
    height: 100%;
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
}

.label-file-upload.drag-active {
    background-color: #ffffff;
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
}


.drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.overflow {
    overflow: inherit
}