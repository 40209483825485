.modal {
    --height: fit-content;

    .header {
        width: fit-content;
        margin: auto
    }

    .error {
        width: fit-content;
        margin: 5px auto
    }
}

.option {
    text-align: right;
}

.buttonContainer {
    display: flex;
    justify-content: space-evenly;
}

.marginBottom {
    margin-bottom: 30px;
}

.marginTop {
    margin-top: 30px;
}

.image {
    height: 143px;
    margin: auto;
}

.checkboxContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .checkboxItem {
        width: 40%;
        margin: 10px 5px 0 5px;
    }
}