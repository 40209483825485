.none {
    display: none;
}

/* .group-row {
    padding-bottom: 100px;
}
 */
/* .group-overview-button {
    margin: 10px auto;
} */

.group-container {
    height: 100%;
    flex-grow: 1;
    margin-right: 1rem;
    border: 1px solid #000;
}

.group-card-content-container {
    overflow: hidden;
    //height: var(--device-content-height);
    height: 100%;

}

.group-card-content-container ion-col {

    overflow: hidden;

    //     @media (max-height: 699px) {
    //         min-height: 60vh;

    //     }


    //     @media (max-height: 700px) {
    //         min-height: 63vh;
    //     }
}

.group-card-content-container ::-webkit-scrollbar {
    display: none;
}

.container {
    height: 100%;
    display: flex;
    justify-content: space-between
}

/* 
.contentHeight {
    height: 85%;
}
 */
.pull-left {
    flex-grow: 1;
}

.pull-right {
    flex-grow: 0;
    padding: 2px;
}

.buttonContainer {
    /* margin-top: 8px; */
    /* height: 80vh; */
    /*  width: 20%; */
    //375px;

    @media (max-width:912px) {
        width: 80vw;

        .gp-btn {
            margin-top: 10%;

            ion-button {
                /* TODO: funktioniert noch  nicht */
                --padding-top: 50px !important;
                --padding-bottom: 50px !important;
            }
        }
    }

    @media (min-width:913px) {

        width: 375px !important;
    }

}

.button-wrapper {
    margin: 1rem 0 1rem 0;
}

.gp-btn {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}




.group-swiper {

    height: 100%;

    .slide-height {
        min-height: calc(var(--device-content-height)) !important;
        max-height: calc(var(--device-content-height)) !important;
    }

    // @media (max-width: 500px) and (min-height: 701px) and (max-height: 950px) {
    //     height: 72vh !important;

    //     .slide-height {
    //         height: 72vh !important;
    //     }
    // }

    // @media (max-width: 500px) and (max-height: 700px) {
    //     height: 65vh !important;

    //     .slide-height {
    //         height: 65vh !important;
    //     }
    // }

}