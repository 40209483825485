.paginationContainer {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    height: fit-content;
    padding: 18px;
    margin: 10px 0;
    border-width: 1px;
    /* border-style: solid; */
    /* border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))); */


    .pageSizeContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: fit-content;
        max-width: 20%;
        min-height: 100%;
    }

    .totalItemsContainer {
        position: absolute;
        top: 0;
        right: 0;
        width: 15%;
        min-height: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 15px;

        .infobox {
            padding: 5px 0;
        }
    }
}