.eventCategorieBadge {
    position: absolute;
    top: 6%; //10px;
    left: 6%;
    width: 25px;
}


.textOverflow {
    text-overflow: ellipsis;
}

.eventCategoryTitle {

    height: 6%;
    color: white;
    font-weight: bolder !important;
    overflow: hidden;
    text-align: center;
    //white-space: nowrap;
    margin: 1% 0 !important;
    font-size: 70% !important;

    @media screen and (max-height: 700px),
    screen and (max-width: 400px) {
        font-size: 50% !important;
    }
}

@mixin eventBtn($width, $height ) {

    height: $height;
    width: $width; //110px;
    margin-inline: 0;
}

.eventOverviewButton {


    @media (min-width: 0px) and (max-width: 300px) {
        @include eventBtn(90%, 50%);
    }

    @media screen and (max-height: 700px) {
        //@include eventBtn(50%, 70%);

        svg {
            width: 20px;
            height: 20px;
        }
    }

    @media (min-width:301px) and (max-width:900px) {
        @include eventBtn(90%, 60%)
    }


    @media (min-width:901px) {
        @include eventBtn(60%, 60%)
    }
}

.paddingBottom {
    padding-bottom: 10%;
}

.middle {
    margin: 20% auto;

    @media screen and (max-height: 700px) {
        margin: 10% auto;
    }
}

.cardFlex {
    gap: 10px;
    display: flex;
    flex-direction: column;

    @media screen and (max-height: 700px),
    screen and (max-width: 400px) {
        gap: 0;
    }
}


.eventBtn {
    padding-top: 9%;
    /*    display: block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);*/
}