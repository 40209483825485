/* 
CSS Tooltip Generator:
http://www.menucool.com/tooltip/css-tooltip
*/
:root {
    --font-size: .5rem;
    --padding: 1px 30px 1px 10px;
    --background: whitesmoke;
    --top-width: 200px
        /* 
        #EEEEEE 
        whitesmoke 
        */
}

.pointer {
    cursor: pointer;
}

.mobile p {
    margin: 1px 0;
}

.tooltip {
    position: absolute;
    display: block;
    width: 100%;
}

.title,
.text,
.subtitle {
    font-size: .8rem;
}

.title {
    font-weight: bold;
    text-decoration: underline;
    text-align: center;
    /* font-size: .6rem; */
    padding-top: 0.1px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 2px 0;
}

.text {
    padding: var(--padding);
    text-align: center;
    font-style: italic;
    text-align: left;
    margin-top: 15px;
    /*  margin-left: 10%;
    margin-right: 10%; */
    /*   padding-left: 10px;
    padding-right: 10px; */

}

.subtitlePadding {
    padding-top: 5px !important;
}

.subtitle {
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
    margin: 0 auto;
    padding-top: 0.1px;
    /* font-size: .51rem; */
    padding-left: 10px;
    padding-right: 10px;
}

/*--------------- RIGHT ---------------------------*/

.moreTop {
    top: 200% !important
}

.right {
    width: fit-content;
    min-width: 9.3vw;
    max-width: 50vw;
    /*  top: 21px;
    right: 40%; */
    /* margin-right: 20px; */
    transform: translate(0, -50%);
    color: #444444;
    background-color: var(--background);
    font-size: var(--font-size);
    border-radius: 8px;
    position: absolute;
    z-index: 99999999 !important;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    /*display: none;*/
}



.tooltip:hover .right {
    display: block;
}


.right i {
    position: absolute;
    top: 50%;
    /* left: -8%; */
    left: -12px;
    margin-top: -12px;
    width: 12px;
    height: 24px;
    overflow: hidden;
}

.right i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 0;
    top: 50%;
    transform: translate(50%, -50%) rotate(-45deg);
    background-color: var(--background);
    /*    border: 1px solid #000000; */
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}


/*--------------- TOP ---------------------------*/

.top {
    width: 200px;
    /*  top: 0%;
    left: calc(var(--top-width) /2); */
    transform: translate(-50%, -100%);
    color: #444444;
    background-color: var(--background);
    font-size: var(--font-size);
    border-radius: 8px;
    position: absolute;
    z-index: 99999999 !important;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    /*display: none;*/
}

.tooltip:hover .top {
    display: block;
}

.top i {
    position: absolute;
    top: 98%;
    left: 30px;
    margin-left: -12px;
    width: 24px;
    height: 12px;
    overflow: hidden;
}

.top i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: var(--background);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}


/*--------------- LEFT ---------------------------*/

.left {
    width: fit-content;
    /* min-width: 150px; */
    /*  top: 50%;
    left: 0; */
    margin-right: 20px;
    transform: translate(0, -50%);
    color: #444444;
    background-color: var(--background);
    font-size: var(--font-size);
    border-radius: 8px;
    position: absolute;
    z-index: 99999999 !important;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    /*display: none;*/
}

.tooltip:hover .left {
    display: block;
}

.left i {
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -12px;
    width: 12px;
    height: 24px;
    overflow: hidden;
}

.left i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: var(--background);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.closeBtnWrapper {
    width: fit-content;
    max-height: 80%;
}

.closeBtn {
    position: absolute;
    /* top: 5%; */
    bottom: auto;
    cursor: pointer;
}