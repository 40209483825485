.itemContainer {

    width: 100%;
}


.container {
    width: 100%;
    height: 63px
}

.textLeft {
    text-align: left;
}

.textRight {
    text-align: right;
}

.label {
    white-space: break-spaces !important;
}

.select {
    min-width: 100%;
    height: 100%;
}

.overflow {
    overflow: inherit
}

ion-select {
    position: relative;
}

.flex {
    display: flex;
}
