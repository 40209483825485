.details {

    ion-col {

        border-right: 1px solid lightgray;

    }

    /* ion-col:nth-child(1) {
        padding-left: 0;
    } */

    ion-col ion-item:last-child {
        --border-width: 0 !important;
    }
}

.modal {
    --height: 50%;
}