.blinkArrow1,
.blinkArrow2,
.blinkArrow3 {

    width: 30px;
    height: 30px;
    position: fixed;
    left: 45%;
    right: 45%;
}

$arrow-between-padding: 2%;

.blinkArrow1 {

    bottom: calc(2% + $arrow-between-padding);
    z-index: 99999;
    animation: blink 1.8s infinite;
}

.blinkArrow2 {
    bottom: calc((2% * 1.5) + $arrow-between-padding);
    z-index: 99999;
    /* background-color: red; */
    animation: blink 1.9s infinite;
}

.blinkArrow3 {
    bottom: calc((2% * 2) + $arrow-between-padding);
    z-index: 99999;
    animation: blink 2s infinite;

}

.overflow {
    position: relative;
    width: 100%;
    overflow: auto;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}