@function buttonPosition($n) {
    @return calc(60px*$n)
}

;

/* .fabButton {
    margin-top: 1px;
    padding-top: 1px;
} */

@for $i from 0 through 3 {
    .pos#{$i} {
        margin-left: buttonPosition($i);
    }
}