:root {

    --border-width: .5px
}

.popover {
    width: 300px;

    .center {
        margin: 0 auto;
    }
}

.selected {
    border: 2px solid var(--ion-color-success, #2dd36f);
}

.blink {

    //border-width: var(--border-width);
    animation: blink-animation 2s infinite;
}

.buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.divIcon {
    width: fit-content;
    height: fit-content;

    & * {
        top: 0;
        left: 0;
    }
}

.label {
    color: black;
    //background: white;
    //opacity: .7;
    width: fit-content;
    white-space: nowrap;
    z-index: 99;
}

@keyframes blink-animation {
    0% {
        opacity: 1;
        //border-width: unset;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.tooltip {
    background-color: transparent !important;
    border: 0;
    content: "";
    width: fit-content !important;
    height: fit-content !important;
    cursor: default !important;
    padding: 2px 2px 2px !important;
    border: unset !important;
    box-shadow: unset !important;

    p {
        background-color: rgba($color: gray, $alpha: .5);
        border-radius: 5px;
        padding: 5px 10px;
        color: black;
    }

    &::before {
        margin-top: 0px;
        margin-left: 0px;
        border-bottom-color: unset !important;
        border: unset !important;
    }

}



.cursive {
    font-family: cursive;
}


.courier {
    font-family: 'Courier New', Courier, monospace;
}

.segoe-ui {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.times-new-roman {
    font-family: 'Times New Roman', Times, serif;
}

.franklin-gothic-medium {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.verdana {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.bold {
    font-weight: bold;
}

.bolder {
    font-weight: bolder;
}

.lighter {
    font-weight: lighter;
}