.label {
    white-space: "break-spaces";
}

.textContainer {
    height: 38px;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
}

.textPosition {
    float: right;
}

.overflow {
    overflow: inherit
}

.flex {
    display: flex;
}

.justifStart {
    justify-content: start;
}

.tooltip {
    margin: 15px 0 0 0px;
    padding: 0;
}

.labelWidth {
    width: 90%;
}

.fullWidth {
    width: 100%;
}

.allowSelect {
    user-select: text !important;
}