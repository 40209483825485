.containerInput {
    /* text-align: right; */
    width: 100%;
    margin: 5px 0
}

.inputAlign {
    text-align: right;
}

.container {
    width: 100%;
}

.label {
    white-space: break-spaces !important;
}

.noArrows {

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}

.overflow {
    overflow: inherit
}

.flex {
    display: flex;
}

.justifStart {
    justify-content: start;
}

.tooltip {
    margin: 15px 0 0 0px;
    padding: 0;
}