@use "../../../global.scss";

.event-row {
    // padding-bottom: 100px;

    @media screen and (max-height: 700px) {
        padding-bottom: 50px;
    }
}

.event-overview-button {
    margin: 10px auto;
}

.event-overview-grid {

    height: 100%;

    // ion-col {
    //     height: 170px;
    // }

    svg {
        width: 40px;
        height: 40px;
    }
}

//.event-overview-grid {
//     min-height: var(--device-content-height);

//     @media (max-height: 699px) {
//         // min-height: 60vh;

//         ion-col {
//             height: 170px;
//         }

//         svg {
//             width: 40px;
//             height: 40px;
//         }

//     }


//     @media (max-height: 700px) {
//         min-height: 63vh;

//         ion-col {
//             height: 170px;
//         }

//         svg {
//             width: 40px;
//             height: 40px;
//         }

//     }

//     @media (max-width:281px) {
//         ion-col {
//             --ion-grid-column-padding: 0 !important
//         }

//     }
// }

// .event-swiper {
//     @media (max-width: 500px) and (min-height: 701px) and (max-height: 950px) {
//         height: 72vh;
//     }

//     @media (max-width: 500px) and (max-height: 700px) {
//         height: 65vh;
//     }
// }

.event-swiper {
    height: 100%;
}