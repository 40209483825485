.list {
    display: flex;
    flex-direction: column;
    height: 100%;
    //    overflow: auto;

    //

    //     @media screen and (max-width:900px) {

    //         max-height: calc(var(--device-content-height) - 7vh);
    //     }
}

ion-content::-webkit-scrollbar {
    display: none;
}

.fullWidth {
    width: 100%;

    .col:last-child {
        min-width: 120px;
        max-width: 120px;
    }

}

.link {
    color: var(--ion-text-color);
    width: 100%;
    text-decoration: none;
}

.pointer {
    cursor: pointer;
}

.row {

    &.noExpand {

        margin-left: calc(var(--padding-start) + var(--inner-padding-end) + var(--accordion-no-expand-extra, 4px))
    }

    &.header {
        padding-left: 16px;
        font-weight: bold;

    }

    // &.even {
    //     /* background: whitesmoke; */
    // }

    // &.odd {}

    // padding: 1em 0 1em 1em;



    display: flex;
    flex: 1;
    flex-wrap: wrap;

    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), .4);

    min-height: 50px;
}

.center {
    display: flex;
    align-items: center;
}


.col {


    display: flex;
    flex: 1;
    overflow: hidden;

    &.width-200 {
        min-width: 200px;
        max-width: 200px;
    }

    &.flexNextLine {
        min-width: 100vw;
    }

    svg {
        margin: .5em;
    }

    &.halfWidth {
        min-width: 80px;
        max-width: 80px;
    }


    &.grow {
        flex-grow: 1;
        max-width: 100%;
        min-width: 0;
        flex-wrap: wrap;
    }

    &.right {
        justify-content: end;
    }

    &.settingsIcon {

        margin: 18px 0 18px 0;

        min-width: 50px !important;
        max-width: 50px !important;

        ion-icon {
            padding: 0 16px;


            // um das settings Icon noch mehr nach rechts zu bringen in der Mobilen version
            // @media (max-width: 992px) {
            //     padding: 0 0 0 25px;
            // }
        }
    }

    &.eventIcon {
        padding-right: 5px;
    }

}

.elementGroupSpacer {
    min-width: 40px;
    max-width: 40px;
}

.childContainer {
    @media (max-width: 992px) {
        padding-left: 40px; //55px;
    }

    @media (min-width: 992px) {
        padding-right: 63px; //55px;
    }


    &.paddingLeft {

        .col {
            padding-left: 1vw;
        }
    }

    .col:first-child {
        padding-left: 0
    }
}

.mobile {
    width: 100vw;

}

.marginLeft {
    margin-left: 31px;
}

.menuIcon {
    padding: 10px;
    position: absolute !important;
    height: 100%;
    width: 5%;
    left: 0;
    top: 0;

}

.status {

    max-width: 65px;
    min-width: 65px;

    @media (max-width: 992px) {
        max-width: 45px;
        min-width: 45px;
    }

}

.number {

    max-width: 85px;
    min-width: 85px;

    @media (max-width: 992px) {
        max-width: 65px;
        min-width: 65px;
    }

}


.settingsIcon {
    width: 15px;
}

.container {
    --padding-start: 20px;
}

.paddingEnd {
    --inner-padding-end: 40px !important;
}


.menu {
    ion-content {
        --max-height: 50%
    }
}

.rowTextCenter {
    margin: auto 0;
}

.rowTextCenterWithMarginAround {
    margin: auto 10px;
}

.noContent {
    div {
        width: fit-content;
        margin: 0 auto;
    }
}

.rowContainer {

    height: 100%;

    // min-height: var(--device-content-height);
    // max-height: var(--device -content-height);

    overflow: auto;


    // @media (min-width: 992px) {
    //     max-height: 50vh;
    // }

    // // @media (max-width: 992px) {
    // //     margin-left: -10px;
    // // }


}

.rowContainer::-webkit-scrollbar {
    display: none;
}