.col {

    align-self: start;

    @media screen and (max-width:900px) {}

    &.size1 {
        min-width: 3em;
        max-width: 3em;
    }

    &.size2 {
        min-width: 6em;
        max-width: 6em;
    }

    &.size3 {
        min-width: 12em;
        max-width: 12em;
    }

    &.size4 {
        min-width: 24em;
        max-width: 24em;
    }

    &.sizeFull {
        min-width: 100%;
        max-width: 100%;
    }
}