.right {
    border-right: 1px solid lightgray;
}

.list {

    padding: 0;
    margin: 0;
}

.fullWidth {
    width: 93vw;
}