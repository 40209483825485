:root {

    --radius: 0px;
}

.autocomplete {
    position: fixed;
    max-height: 180px;
    z-index: 20005;
    //border-radius: 5px;
    padding: 10px;

}

.modal {
    max-height: 500px;

    @media (max-width:400px) and (max-height:800px) {
        max-height: 50dvh;
    }
}

.card {
    padding: 0;
    border: .5px solid black;
    //border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(rgb(92, 90, 90), .8);
}

ion-item {
    font-size: 0.8rem;
}

.radius {
    //border-radius: var(--radius);

    ion-item {
        //border-radius: var(--radius) var(--radius) 0 0;
    }

    ion-list {
        //border-radius: 0 0 var(--radius) var(--radius);
    }
}

.shadow {
    box-shadow: 5px 5px 10px rgba(rgb(92, 90, 90), .8);
}

.card {
    margin: 5px 0 0 0;
    margin-inline: 0;
    border: .5px solid black;
}

.mobileCheckbox {
    width: 100vw;
    --background: transparent;
    --background-checked: transparent;
    --border-color: transparent;
    --border-color-checked: transparent;
    --border-radius: 0;
    --border-style: none;
    --border-width: 0;
    --checkmark-color: transparent;
    --checkmark-width: 0;
    --size: 0;
}

.list {
    padding: 0;
}

.closeButton {
    font-weight: bold;
    color: white;
    padding: 0 5px 5px 5px;
    text-align: right;
    cursor: pointer;
}

.autocomplete::-webkit-scrollbar {
    display: none;
}

.textContainer {
    overflow: auto;
    //margin: 0 auto;
    max-height: 118px;
}

.InputText {
    //text-align: center;
}

.text {
    //text-align: center;
}

.center {
    width: fit-content;
    margin: 0 auto;
}

.textContainer::-webkit-scrollbar {
    display: none;
}

.pointer {
    cursor: pointer;
}

.backdrop {
    --backdrop-opacity: 0.5 !important;
}