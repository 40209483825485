.dateTime {

    ion-datetime-button::part(native) {
        width: 50%;
        margin: 0;
    }
}


.filterButtonContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;

    ion-button {
        width: 50%;
        height: 50%;

        span {
            margin-right: 10px;
        }
    }
}

.col {

    align-self: start;

    @media screen and (max-width:900px) {}

    &.size0 {
        min-width: 1em;
        max-width: 1em;
    }

    &.size1 {
        min-width: 3em;
        max-width: 3em;
    }

    &.size1_5 {
        min-width: 5em;
        max-width: 5em;
    }

    &.size2 {
        min-width: 6em;
        max-width: 6em;
    }

    &.size3 {
        min-width: 12em;
        max-width: 12em;
    }

    &.size4 {
        min-width: 24em;
        max-width: 24em;
    }

    &.sizeFull {
        min-width: 100%;
        max-width: 100%;
    }
}