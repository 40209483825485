.label {
    margin: 5px 0;
}

.devider {
    margin: 4% 0 0 0;
}

.row,
.line {
    width: 100%;
    margin: 10px 0;
}

.line {
    display: flex;
    justify-content: space-evenly;
}

.row {
    section {
        margin: 20px 0;
    }
}