.event-select {
    max-width: 20vw;

    ion-select::part(text) {
        text-wrap: wrap;
    }
}

.col {

    align-self: start;

    @media screen and (max-width:900px) {}

    &.size1 {
        min-width: 3em;
        max-width: 3em;
    }

    &.size2 {
        min-width: 6em;
        max-width: 6em;
    }

    &.size3 {
        min-width: 12em;
        max-width: 12em;
    }

    &.size4 {
        min-width: 24em;
        max-width: 24em;
    }

    &.sizeFull {
        min-width: 100%;
        max-width: 100%;
    }
}


.details {

    ion-col {

        border-right: 1px solid lightgray;

    }

    /* ion-col:nth-child(1) {
        padding-left: 0;
    } */

    ion-col ion-item:last-child {
        --border-width: 0 !important;
    }
}

.dateTime {

    ion-datetime-button::part(native) {
        width: 50%;
        margin: 0;
    }
}