.flag-locale {

    &.de {

        background-color: blue;

    }

}

.alertMessage {
    user-select: text;
}

.versionInfo {
    font-size: smaller;
    color: lightgray;
    float: right;
}