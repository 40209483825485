.checkboxContainer {
    width: 100%;
    min-height: 40px
}

.checkboxPosition {
    float: right;
    margin: 2px 0;
}

.more {
    width: 100%;

    &:after {
        content: "...";
        position: relative;
        left: 50%;
        transform: translateX(50%);
    }
}

.children ion-item {
    width: 100%;
}

.overflow {
    overflow: inherit
}


.flex {
    display: flex;
}

.container {
    ion-checkbox {
        position: relative;
    }
}