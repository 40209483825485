.container {
    position: relative;
    padding: 0;
    margin: 0;
    cursor: pointer;
    min-height: 20px;
    min-width: 20px;

    @media screen and (max-width:900px),
    screen and (max-height:400px) {
        display: flex;
        flex-direction: column;

        ion-label {
            margin: 5px 0 0 0;
        }
    }
}

.middle {
    display: flex;
    justify-content: center;
}

.border {
    border: 1px solid black;
}

.disabled {
    border-color: var(--ion-color-medium);
}

.checkedBorder {
    border-color: var(--ion-color-success);
}

.uncheckedBorder {
    border-color: var(--ion-color-danger);
}